@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/typography';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/mixins';

.custom_contest_overlay {
  z-index: 9999999;
  @include media(m-device) {
    top: 56px;
  }
}
.container {
  padding: 0 24px;
  position: relative;
  border-radius: 12px;
  background-image: url('/front-static/images/easter/popin_easter.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 704px;
  height: 466px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.contest_modal {
  padding: 0 24px;
  position: relative;
  border-radius: 12px;
  background-image: url('/front-static/images/easter/popin_easter.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 704px;
  height: 466px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  @include media(m-device) {
    min-width: 100%;
    padding: 20px;
  }

  &__close_button {
    position: absolute;
    right: 24px;
    top: 24px;

    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

    &:hover, &:focus {
      outline: none;
    }
  }

  &__transition_enter {
    opacity: 1;
    transform: translateY(0);
  }

  &__transition_enter {
    opacity: 0.01;

    .contest_modal {
      transform: translateY(-100px);
    }
  }

  &__transition_enter_active {
    opacity: 1;
    transition: opacity 500ms cubic-bezier(0.23, 1, 0.32, 1), transform 500ms cubic-bezier(0.23, 1, 0.32, 1);

    .contest_modal {
      transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1);
      transform: translateY(0);
    }
  }

  &__transition_exit {
    opacity: 1;
    transform: translateY(0);
  }

  &__transition_exit_active {
    opacity: 0.01;
    transition: opacity 500ms cubic-bezier(0.23, 1, 0.32, 1);

    .contest_modal {
      transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1);
      transform: translateY(-100px);
    }
  }

  .fake__input {
    display: none;
  }

  .cta {
    position: absolute;
    top: 208px;
    right: 36px;
    margin: 36px auto;
    padding: 18px 54px;
    border-radius: 30px;
    background-color: #F80000;
    font-size: 20px;;
    font-family: $primary-reg;
    @include media(m-device) {
      margin-top: 16px;
      font-size: 12px;
    }

    &:hover {
      background-color: #A99CF7 !important;
    }
  }

  .close__cta {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 5;
    cursor: pointer;
  }
}

