@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/typography';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/mixins';

.custom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 360px;
  height: fit-content;
  height: -moz-fit-content;


}

.modal {
  display: flex;
  flex-direction: column;


  &__icon {
    width: 76px;
    height: 76px;
    margin: 56px auto 32px auto;
    border-radius: 12px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  & > h5 {
    text-align: center;
    font-size: 20px;
  }

  &__text {
    text-align: center;
    margin: 9px 0 24px 0;
  }

  &__btn__container {
    display: flex;
    flex-direction: column;
  }

}





