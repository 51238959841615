@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/typography';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 944px;
  height: 558px;
  margin: 30px 20px 0 14px;
  border-radius: 16px 16px 0 0;
  background-color: $brand-secondary-muted;

  &__white {
    background-color: $white;
  }

  @include media(m-device) {
    margin-top: 32px;
    height: 630px;
    width: 90vw;
  }
}


.toolbar {

  &__loader {
    height: 2px;
    position: absolute;
    top: 59px;
    left: 291px;
    max-width: 360px;
    border-radius: 3px;
    background-color: $apple-blue;
  }

  &__icon {
    position: absolute;
    top: 47px;
    left: 638px;
    width: 8px;
    height: 8px;
  }
}


.loader {
  position: absolute;
  top: 0;
}

.title {
  color: $brand-primary;
}

.subtitle {
  color: $txt-primary;
}

.offer {
  z-index: 998;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 0;
  width: 80vw;
  max-width: 1140px;
  margin: 0 auto;

  @include media(m-device) {
    padding: 56px 0 48px 0;
    width: 90vw;
  }

  &__card {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 896px;
    height: 488px;

    &__active {
      opacity: 0;
      animation: fadeIn ease-in 600ms;
    }
  }
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.slider {
  width: 90vw;
  min-width: 246px;

  &__title {
    margin-bottom: 34px;
  }

  &__dots {
    position: absolute;
    bottom: 580px;
    height: 8px;
    width: 8px;
    background-color: $white;
    border-radius: 50%;
    display: inline-block;
    @include transition;

    &:hover {
      background-color: lighten($color: $brand-primary, $amount: 10%);
    }

    &__active {
      background-color: $brand-primary;
    }
  }

  &__card {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 547px;
    width: 225px;
    border-radius: 6px;

    &__img {
      width: 100%;
      height: 667px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 6px;
      margin-top: 5px;
    }

  }
}

.heart {
  position: absolute;
  top: 600px;
  right: -5%;
  overflow: hidden;
  z-index: 456;
  @include hide-on-m-device();
}

.sphere__left {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 600px;
  left: 0;
  overflow: hidden;
  z-index: 456;
  @include hide-on-m-device();
}

.sphere__right {
  position: absolute;
  top: 300px;
  right: -5%;
  width: 18px;
  height: 18px;

  overflow: hidden;
  z-index: 456;
  @include hide-on-m-device();
}

.message {
  position: absolute;
  top: 800px;
  left: -10%;
  width: 261px;
  height: 88px;
  overflow: hidden;
  z-index: 456;
  @include hide-on-m-device();
  border-radius: 28px;
  animation: float 7s ease-in-out infinite;
}

.participation_1 {
  z-index: 1;
  position: absolute;
  top: 380px;
  left: -10%;
  width: 181px;
  height: 48px;
  overflow: hidden;
  @include hide-on-m-device();
  border-radius: 28px;
  animation: float 8s ease-in-out infinite;
}

.participation_2 {
  z-index: 1;
  position: absolute;
  top: 800px;
  right: -10%;
  width: 181px;
  height: 48px;
  overflow: hidden;
  @include hide-on-m-device();
  border-radius: 28px;
  animation: float 6s ease-in-out 2s infinite;

}

.poteurs {
  position: absolute;
  top: 340px;
  z-index: 1;
  right: -5%;
  width: 308px;
  height: 48px;
  overflow: hidden;
  @include hide-on-m-device();
  border-radius: 28px;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

