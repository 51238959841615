@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/typography';

.wrapper {
}

.options {
  display: flex;
  flex-direction: column;
  padding: 64px 0 48px 0;
  width: 80vw;
  max-width: 1140px;
  margin: 0 auto;
  @include media(m-device) {
    padding: 48px 0 48px 0;
    width: 90vw;
  }
}


.grid {
  display: flex;
  justify-content: space-between;
  margin-top: 96px;
  @include media(m-device) {
    flex-direction: column;
    margin-top: 48px;
  }

  &__left {
    width: 49%;
    display: flex;
    flex-direction: column;
    @include media(m-device) {
      width: 100%;
    }
  }

  &__right {
    width: 49%;
    display: flex;
    flex-direction: column;
    @include media(m-device) {
      width: 100%;
      margin-top: 56px;
    }
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    padding: 48px;
    border-radius: 8px;
    background-color: $white;
    border: solid 1px $ui-quaternary;

    @include media(m-device) {
      padding: 36px 24px 24px 24px;
    }


    &__txt {
      margin: 4px 0 8px 0;
    }

    &__label {
      position: absolute;
      top: -16px;
      right: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      padding: 8px;
      border-radius: 4px;
      background-color: $brand-primary;

      & > div {
        font-family: $primary-reg;
      }
    }

    &__cta {
      border: none;
      background-color: $white;
      color: $brand-primary;
      font-family: $primary-medium;
      font-size: $level3;
      outline: none;
      cursor: pointer;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }

      @include media(m-device) {
        font-size: $level2;
      }
    }

    &__left {
      height: 296px;
      justify-content: space-between;
    }

    &__right {
      height: 296px;
      @include media(m-device) {
        height: 242px;
      }
    }
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  margin: 0 0 12px 0;
  border-radius: 9.3px;

  &__partners {
    background-color: $brand-secondary-muted;
  }

  &__coin {
    background-color: $brand-accent-lighten;
  }


}


