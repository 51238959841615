@import '../../../assets/styles/colors';
@import '../../../assets/styles/osflm_colors';
@import '../../../assets/styles/mixins';
@import '../../../assets/styles/typography';
@import '../../../assets/styles/fonts';

.contest__banner {
  z-index: 3001;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px 8%;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  background-position: bottom;
  background-image: url('/front-static/images/osflm/osflm-banner.png');

  @media (max-width: 1000px) {
    background-size: cover;
    min-height: 134px;
    background-image: url('/front-static/images/osflm/osflm-banner-mobile.png'), url('/front-static/images/osflm/osflm-text.png');
  }

  &__mobile {
    @media (min-width: 1000px) {
      display: none;
    }
  }

  &__close {
    z-index: 3002;
    cursor: pointer;
    left: 97.5%;
    bottom: 20%;
    position: absolute;
    @media (max-width: 1000px) {
      left: 94%;
      top: 0;
    }
  }

  &__osflmText {
    display: none;
    @media (max-width: 1000px) {
      display: block;
      max-width: 50%;
      height: auto;
      width: 100%;
      margin-left: 35%;
      margin-top: -10%;
    }
  }

  &__lpcLogo {
    display: none;
    @media (max-width: 1000px) {
      display: block;
      margin-right: 20px;
    }
  }

  &__virginLogo {
    display: none;
    @media (max-width: 1000px) {
      display: block;
    }
  }

  &__date {
    display: none;
    @media (max-width: 1000px) {
      display: inline-block;
      font-family: $odvp-primary-bold;
      font-weight: 600;
      color: #274342;
      margin-left: 52%;
    }
  }

  &__osflm_logo {
    display: flex;
    justify-content: center;
    margin-top: -16px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__desc {
    display: none;
    @media (max-width: 1000px) {
      display: inline-block;
      font-family: $odvp-primary-bold;
      font-weight: 600;
      font-size: 9px;
      color: #274342;
      margin-left: 52%;
    }
  }

  &__amount {
    background-color: $osflm-secondary;
    color: $white;
  }

  &__participateBtn {
    background-color: $osflm-secondary;
    color: $white;
    border-radius: 42px;
    border: none;
    padding: 4px 12px;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  }

  .logos {
    display: none;
    @media (max-width: 1000px) {
      display: flex;
      align-items: center;
      margin-left: 5% ;
    }
  }
}
