@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/typography';


.button {
  z-index: 999;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  @include border-radius(24px);
  font-family: $odvp-primary-bold;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  background-color: $odvp-greyish-brown;
  color: $white;
  cursor: pointer;
  outline: none;
  @include transition;


  &:hover {
    background-color: #262422;
  }

}




