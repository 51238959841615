@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/typography';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 48px 0 96px 0;
  width: 80vw;
  max-width: 1140px;
  margin: 0 auto;
  @include media(m-device) {
    padding: 48px 0 48px 0;
    width: 90vw;
  }


  .service {
    display: flex;
    justify-content: space-between;
    @include media(m-device) {
      flex-direction: column;
    }

    &__card {
      width: 31%;
      margin-top: 96px;
      @include media(m-device) {
        width: 100%;
        margin-top: 48px;

      }

      &__image {
        border-radius: 16px;
        border: solid 1px $ui-quaternary;
        height: 202px;
        background-position: center;
        background-repeat: no-repeat;
        @include media(m-device) {
          height: 166px;
        }
      }

      & > h5 {
        margin: 16px 0 8px 0;
        @include media(m-device) {
          margin: 8px 0 2px 0;
        }
      }

    }
  }


}
