@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/typography';


.heading {
  display: flex;
  flex-direction: column;
  align-items: center;


  & > h1 {
    text-align: center;
    max-width: 800px;
    line-height: 1.14;
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    & > img {
      margin: 0 12px 0 -24px;
    }
  }

  &__subtitle {
    color: $brand-primary;
    margin-bottom: 8px;
  }

  & > p {
    text-align: center;
    max-width: 750px;
    line-height: 1.78;
  }

  & > div {
    text-align: center;
    max-width: 750px;
    line-height: 1.78;
  }

  & > div > b {
    font-family: $primary-SemiBold !important;
    color: $txt-primary;
  }
}


