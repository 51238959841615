@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/fonts';
@import '../../../../../assets/styles/mixins';
@import '../../../../../assets/styles/typography';


.navigation {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 488px;
  height: 56px;
  margin: 38px auto 48px auto;
  border-radius: 8px;
  box-shadow: 12px 12px 48px 0 $box-shadow;
  background-color: $white;
  @include transition();


  &__tab {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 158px;
    height: 48px;
    border: none;
    font-family: $primary-reg;
    font-size: $level3;
    color: $txt-secondary;
    cursor: pointer;
    @include border-radius(8px);
    @include transition;

    &:hover {
      @include transition;
      background-color: $brand-primary;
      color: $white;
    }

    &__active {
      background-color: $brand-primary;
      color: $white;
    }
  }

}



