@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/typography';
@import '../../../../assets/styles/fonts';

.contest__container {
  display: flex;
  flex-direction: row;

  .contest__banner {
    position: fixed;
    bottom: -3px;
    background-repeat: round;
    cursor: pointer;
    box-shadow: 37px 12px 48px rgb(163 175 194 / 18%);
    width: 100%;
    max-height: 240px;
    //To Hide Default Land bot Live chat 2147483000
    z-index: 2147483001;


    & > img {
      width: 100%;
    }

    &__close {
      z-index: 3002;
      cursor: pointer;
      left: 97.5%;
      bottom: 100%;
      position: absolute;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      background-image: url('/front-static/icons/navigation/close.svg');
      @include transition;
      @media (max-width: 1000px) {
        left: 94vw;
      }

      &:hover {
        background-image: url('/front-static/icons/navigation/close-background-grey.svg') !important;
      }
    }

    &__admin_header {
      width: 100%;
      min-height: 55px;
      text-align: center;
      display: flex;
      position: fixed;
      bottom: 0;
      justify-content: center;
      align-items: center;
      background-color: $ui-warning-secondary;
      margin-top: -55px;
      padding: 6px 12px;
      //To Hide Default Land bot Live chat 2147483000
      z-index: 2147483002;

      @include media(s-device) {
        font-size: $level1;
      }

      & > span > a {
        color: $txt-alert;
      }

      & > span > b {
        font-family: $primary-bold;
      }

      &__icon {
        padding-right: 7px;
      }
    }
  }
}
