@import '../../../assets/styles/colors';
@import '../../../assets/styles/mixins';
@import '../../../assets/styles/typography';
@import '../../../assets/styles/fonts';

.contest__banner {
  z-index: 1000;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  background-position: bottom;

  &__close {
    z-index: 1001;
    position: fixed;
    right: 6px;
    bottom: 10.5vh;
    cursor: pointer;
    background-color: $brand-primary-shade;
    border-radius: 6px;

    @include media(s-device) {
      background-color: $brand-primary;
    }
  }

  &__close:hover {
    background-color: $brand-primary;
    cursor: pointer;
  }
}
