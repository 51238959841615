@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/typography';
@import '../../../../assets/styles/fonts';

.banner {
  z-index: 3001;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  width: 100vw;
  padding: 24px 8%;
  background-image: url('/front-static/images/odvp/odvp-banner-background.png');
  @media (max-width: 1000px) {
    height: 46vw;
    padding: 0;
    background-image: url('/front-static/images/odvp/banner_mobile_background.png');
    background-blend-mode: darken;
    background-position: left 0 top 0, left 0 bottom 0;
    background-repeat: no-repeat;
    background-color: white;
    background-size: cover;
  }

  &__odvp {
    margin: 16px -10px 0 0;
    max-width: 284px;
    height: auto;
    width: 100%;
  }

  &__first {
    @media (max-width: 1000px) {
      order: 2;
      margin-top: 40px;
    }
  }

  &__third {
    display: flex;
    align-items: center;
    padding: 10px;
    @media (max-width: 1000px) {
      order: 1;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-right: 15px;
      margin-bottom: -58px;
    }
  }

  &__fourth {
    @media (max-width: 1000px) {
      order: 4;
    }
  }

  &__second {
    display: flex;
    align-items: center;
    padding: 10px;
    @media (max-width: 1000px) {
      order: 3;
    }
  }

  &__star {
    max-width: 30px;
    height: auto;
    width: 100%;
    margin-right: 7px;
  }

  &__virgin {
    max-width: 45px;
    height: auto;
    width: 100%;
  }

  &__lpc {
    max-width: 120px;
    height: auto;
    width: 100%;
  }

  &__divider {
    width: 1px;
    height: 25px;
    border: solid 1px #db1c12;
    background-color: #db1c12;
  }

  &__text_message_1 {
    font-family: $primary-reg;
    font-size: 19px;
    color: #FFFFFF;
    padding: 0 9px;

    @include media(m-device) {
      font-size: 14px;
    }
  }

  & > a {
    cursor: pointer;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
}

.participateBtn {
  background-color: #e22b76;
  width: 100%;
  max-width: 183px;

  @media (max-width: 1000px) {
    margin-bottom: 25px;
  }
}

.close {
  cursor: pointer;
  left: 95%;
  position: absolute;
  @media (max-width: 1000px) {
    left: 95vw;
    top: 3%;
  }
}
