@import '../../../assets/styles/colors';
@import '../../../assets/styles/mixins';
@import '../../../assets/styles/typography';
@import '../../../assets/styles/fonts';


.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 13.5px;

  &__success {
    background-color: $ui-success-tint-1;
  }

  &__warning {
    background-color: $ui-warning-lighten;
  }

  &__error {
    background-color: $ui-error-tint-1;
  }

  &__informaiton, &__spy {
    background-color: $ui-quaternary;
  }

  &__gift {
    background-color: $brand-primary-muted;
  }

  &__coin {
    background-color: $brand-accent-secondary;
  }

}
