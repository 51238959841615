@import '../../../assets/styles/colors';
@import '../../../assets/styles/fonts';
@import '../../../assets/styles/mixins';


.homePageWrapper {
  max-width: 100vw;
  margin: 0 auto;
  overflow: hidden;

  &__alert_banner {
    position: fixed !important;
  }
}
