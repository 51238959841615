@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/typography';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/mixins';

.overlay {
  z-index: 9999999;
  @include media(m-device) {
    top: 56px;
  }
}

.modal {
  padding: 0 24px;
  position: relative;
  border-radius: 12px;
  background-image: url('/front-static/images/odvp/background-odvp-poppins.png');
  background-position: center;
  width: 719px;
  height: 468px;
  display: flex;
  justify-content: center;
  @include media(m-device) {
    min-width: 100%;
    padding: 20px;
  }

  h3 {
    text-transform: uppercase;
    text-align: center;
    margin: 32px 0;
  }

  &__close_button {
    position: absolute;
    right: 24px;
    top: 24px;

    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

    &:hover, &:focus {
      outline: none;
    }
  }

  &__transition_enter {
    opacity: 1;
    transform: translateY(0);
  }

  &__transition_enter {
    opacity: 0.01;

    .modal {
      transform: translateY(-100px);
    }
  }

  &__transition_enter_active {
    opacity: 1;
    transition: opacity 500ms cubic-bezier(0.23, 1, 0.32, 1), transform 500ms cubic-bezier(0.23, 1, 0.32, 1);

    .modal {
      transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1);
      transform: translateY(0);
    }
  }

  &__transition_exit {
    opacity: 1;
    transform: translateY(0);
  }

  &__transition_exit_active {
    opacity: 0.01;
    transition: opacity 500ms cubic-bezier(0.23, 1, 0.32, 1);

    .modal {
      transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1);
      transform: translateY(-100px);
    }
  }

  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 80px;
    line-height: 176px;
    color: white;
    font-family: $odvp-primary-2022;
  }

  .subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    font-family: $primary-reg;
    width: 383px;
    text-align: center;
    margin: 0 auto;
    color: white;
  }

  .fake__input {
    display: none;
  }

  .cta {
    margin: 36px auto;
    padding: 18px 54px;
    border-radius: 30px;
    background-color: $odvp-btn;
    font-size: 20px;;
    font-family: $primary-reg;
    @include media(m-device) {
      margin-top: 16px;
      font-size: 12px;
    }

    &:hover {
      background-color: #A99CF7 !important;
    }
  }

  .close__cta {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
}

